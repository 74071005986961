import { useQuery } from '@tanstack/react-query'
import fetchMultipleSectionsByValueSearch from '../../../../../queries/algolia/fetchMultipleSectionsByValueSearch'
import HeaderSearchResultsCategories from './HeaderSearchResultsCategories'
import HeaderSearchResultsPages from './HeaderSearchResultsPages'
import HeaderSearchResultsProducts from './HeaderSearchResultsProducts'
import Loader from '../../Loader/Loader'
import useCurrentLocale from '../../../../core/hook/useCurrentLocale'
import { useGetClientAlgoliaSearch } from '../../../../../queries/algolia/algolia.client'
import { useContext } from 'react'
import { ConfigContext } from '../../../context/ConfigContext'

const useMultipleSearch = (valueSearch: string) => {
  const currentLocale = useCurrentLocale()
  const client = useGetClientAlgoliaSearch()
  const {
    storeConfig: { algoliasearch_credentials_credentials_index_prefix: indexPrefix },
  } = useContext(ConfigContext)

  const queryResult = useQuery({
    queryKey: ['search-multiple', valueSearch],
    queryFn: () =>
      fetchMultipleSectionsByValueSearch(client, indexPrefix, valueSearch, currentLocale),
    enabled: !!valueSearch,
  })

  return queryResult
}

const HeaderSearchResults = ({ valueSearch }: { valueSearch: string }) => {
  const { data: result, isLoading, isError } = useMultipleSearch(valueSearch)

  if (!valueSearch) {
    return null
  }
  if (isLoading) {
    return null
  }
  if (isError) {
    return null
  }

  const { categories, products, pages } = result

  return (
    <>
      {valueSearch && result ? (
        <div className='header-search-results'>
          <div className='header-search-results-left'>
            <HeaderSearchResultsProducts valueSearch={valueSearch} products={products.hits} />
          </div>
          <div className='header-search-results-right'>
            <HeaderSearchResultsCategories categories={categories.hits} />
            <HeaderSearchResultsPages pages={pages.hits} />
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  )
}

export default HeaderSearchResults
