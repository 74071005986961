'use client'
import { FunctionComponent } from 'react'
import { useTranslation } from '../../context/ConfigContext'
import { Button } from '../Button/Button'
import { Modal } from './Modal'

interface ModalContratProps extends ModalInterface {
  contrast: boolean
  onClick: (() => void) | undefined
}

export const ModalContrast: FunctionComponent<ModalContratProps> = ({
  uuid,
  onClose,
  onClick,
  contrast,
}) => {
  const t = useTranslation()
  return (
    <Modal uuid={uuid} dark={true} showLogo center onClose={onClose}>
      <div className='modal-contrast'>
        {contrast && (
          <>
            <h4 className='title-h4'>{t('header-contrast-return-title', {}, false)}</h4>
            <p>{t('header-contrast-return-text', {}, false)}</p>
            <Button
              classname='button white'
              onClick={onClick}
              label={t('header-contrast-return-cta', {}, true)}
            />
          </>
        )}
        {!contrast && (
          <>
            <h4 className='title-h4'>{t('header-contrast-title', {}, false)}</h4>
            <p>{t('header-contrast-text', {}, false)}</p>
            <Button
              classname='button white'
              onClick={onClick}
              label={t('header-contrast-label', {}, false)}
            />
          </>
        )}
      </div>
    </Modal>
  )
}
