import Swiper from 'swiper'
import { Navigation } from 'swiper/modules'

export const BREAKPOINT_ONE_PER_VIEW = '320'
export const BREAKPOINT_THREE_PER_VIEW = '700'
export const SIZE_GAP_SLIDES = 14

export const getFilledSpaceByFullyVisibleSlides = (
  widthOfSlide: number,
  areThreeSlidesPerView: boolean,
) => {
  // get the number of fully visible slides
  const nbOfFullyVisibleSlide = areThreeSlidesPerView ? 3 : 1
  // compute the filled space by fully visible slides
  return widthOfSlide * nbOfFullyVisibleSlide
}

export const getFilledSpaceByGaps = (
  areThreeSlidesPerView: boolean,
  isFirstOrLastSlide: boolean,
) => {
  // get the number of gaps between visible slides
  let nbOfGaps = areThreeSlidesPerView ? 3 : 1
  // If it is an intermediate slide, we have one additional gap
  if (!isFirstOrLastSlide) nbOfGaps++

  // compute the filled space by gaps
  return SIZE_GAP_SLIDES * nbOfGaps
}

const updateArrowBlocWidth = (arrowBlocWidth: number, className?: string) => {
  document
    .querySelectorAll<HTMLElement>(`${className ? '.' + className : ''} .focus .bloc-navigation`)
    .forEach((el) => {
      el.style.width = `${arrowBlocWidth}px`
    })
}

const updateCustomPaginationBullet = (numberOfPages: number, activeIndex: number) => {
  const customSliderPaginationEl = document.querySelector<HTMLElement>(
    '.focus .custom-slider-pagination',
  )
  if (!customSliderPaginationEl) {
    return
  }

  customSliderPaginationEl.textContent = ''

  for (let i = 1; i <= numberOfPages; i++) {
    const li = document.createElement('li')
    li.classList.add('custom-slider-pagination-bullet')
    if (activeIndex === i) {
      li.classList.add('custom-slider-pagination-bullet-active')
    }

    customSliderPaginationEl.appendChild(li)
  }
}

export const onEventCategoriesSwiper = (swiper: Swiper, numberOfcategories: number) => {
  const { width, currentBreakpoint } = swiper
  const widthOfSlide = (swiper.slides[0] as HTMLElement & { swiperSlideSize: number })
    ?.swiperSlideSize
  const areThreeSlidesPerView = currentBreakpoint === BREAKPOINT_THREE_PER_VIEW

  // Workaround because Swiper fails on computing the number of pages for our case
  // Need to substract 2 on number of pages when 3 slides per view
  const numberOfPages = areThreeSlidesPerView ? numberOfcategories - 2 : numberOfcategories
  const activeIndex = areThreeSlidesPerView ? swiper.activeIndex : swiper.activeIndex + 1

  // detect if it is the first of last slide of carousel
  const isFirstOrLastSlide = activeIndex === 1 || activeIndex === numberOfPages

  // compute arrow bloc width
  const filledSpaceByFullyVisibleSlides = getFilledSpaceByFullyVisibleSlides(
    widthOfSlide,
    areThreeSlidesPerView,
  )
  const filledSpaceByGaps = getFilledSpaceByGaps(areThreeSlidesPerView, isFirstOrLastSlide)
  let arrowBlocWidth = width - filledSpaceByFullyVisibleSlides - filledSpaceByGaps
  // If we have two troncaded slides (previous AND next), they are twice smaller
  if (!isFirstOrLastSlide) {
    arrowBlocWidth /= 2
  }

  return {
    activeIndex,
    numberOfPages,
    arrowBlocWidth,
  }
}

export const initSliderForListCategories = (className?: string) => {
  const numberOfcategories = document.querySelectorAll(
    `${className ? '.' + className : ''} .focus`,
  ).length

  if (numberOfcategories === 0) {
    return
  }

  const onChange = (instance: Swiper) => {
    const { activeIndex, numberOfPages, arrowBlocWidth } = onEventCategoriesSwiper(
      instance,
      numberOfcategories,
    )

    updateArrowBlocWidth(arrowBlocWidth, className)
    updateCustomPaginationBullet(numberOfPages, activeIndex)
  }

  const swiper = new Swiper(`${className ? '.' + className : ''} .focus .swiper`, {
    spaceBetween: SIZE_GAP_SLIDES,
    modules: [Navigation],
    watchSlidesProgress: true,
    centeredSlides: true,
    centeredSlidesBounds: true,
    centerInsufficientSlides: true,
    navigation: {
      prevEl: '.bloc-navigation-left',
      nextEl: '.bloc-navigation-right',
      disabledClass: 'bloc-navigation-disabled',
    },
    breakpoints: {
      [BREAKPOINT_ONE_PER_VIEW]: { slidesPerView: 1.15 },
      [BREAKPOINT_THREE_PER_VIEW]: { slidesPerView: 3.1 },
    },
    on: {
      slideChange: onChange,
      resize: onChange,
      init: onChange,
    },
  })

  return swiper
}
