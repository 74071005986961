'use client'

import { useState } from 'react'
import { useIsomorphicLayoutEffect } from '../../../core/hook/useIsomorphicLayout'
import { CustomInfoPush } from '../../../domain/infoPush.types'
import { useInfoPushLocalStorage } from '../../../core/hook/useInfoPushLocalStorage'
import Icon from '../Icon/Icon'
import { Icons } from '../Icon/constants'
import { useTranslation } from '../../context/ConfigContext'

type MessagePushProps = {
  infoPush: CustomInfoPush
}

const initColorsFromInfoPush = (infoPush: CustomInfoPush) => {
  // background-color
  const messagePushEl = document.querySelector<HTMLElement>('.message-push')
  if (messagePushEl) {
    messagePushEl.style.backgroundColor = infoPush.bannerColor
  }

  // color of texts and svgs
  document
    .querySelectorAll<HTMLElement>('.message-push a, .message-push p, .message-push span')
    .forEach((el) => {
      el.style.color = infoPush.textColor
    })
  document.querySelectorAll<HTMLElement>('.message-push svg path').forEach((el) => {
    el.style.stroke = infoPush.textColor
  })
}

const InfoPush = ({ infoPush }: MessagePushProps) => {
  const { showInfoPush, onCloseInfoPush } = useInfoPushLocalStorage()
  const [isContentOpen, setIsContentOpen] = useState(false)
  const t = useTranslation()

  useIsomorphicLayoutEffect(() => {
    initColorsFromInfoPush(infoPush)
  }, [infoPush])

  const closeOrOpenContent = () => {
    setIsContentOpen(!isContentOpen)
  }

  if (!showInfoPush) {
    return null
  }


  return (
    <div
      className={`message-push ${isContentOpen ? 'message-push--active' : ''}`}
      onClick={closeOrOpenContent}>
      {infoPush.content && <div dangerouslySetInnerHTML={{ __html: infoPush.content }} />}

      <button
        onClick={onCloseInfoPush}
        className='message-push-close'
        aria-label={t('Close', {}, false)}>
        <Icon iconName={Icons.CLOSE} />
      </button>
    </div>
  )
}

export default InfoPush
