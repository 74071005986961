'use client'

import { FunctionComponent, useEffect } from 'react'
import Swiper from 'swiper'
import { initSliderForListCategories } from '../../utils/ListCategories.utils'
import { initVideoCmsBlock } from './initVideoCmsBlock'

const initFocusSlider = function (className: string) {
  initSliderForListCategories(className)
}

const CmsBlockClient: FunctionComponent<{
  className: string
  shouldRemoveParentClass?: boolean
}> = ({ className, shouldRemoveParentClass = false }) => {
  useEffect(() => {
    initFocusSlider(className)
    if (shouldRemoveParentClass) {
      const elToRemove = document.querySelector('.parent-to-remove')
      if (!elToRemove) {
        return
      }

      elToRemove.replaceWith(...elToRemove.childNodes)
    }
  }, [shouldRemoveParentClass])

  useEffect(() => {
    // Sometimes we have some sliders for mobile
    const selector = `.${className} .swiper-container`

    const elSwiper = document.querySelector(selector)

    if (!elSwiper) {
      return
    }

    new Swiper(selector, {
      spaceBetween: 8,
      slidesPerView: 1,
      breakpoints: {
        1024: {
          enabled: false,
          spaceBetween: 0,
        },
      },
    })
  }, [])

  useEffect(() => {
    // init video
    const elements: any = document.querySelectorAll(`.${className} video.js-autoplay-video`)

    elements?.forEach((el) => {
      const parentEl = el.parentNode
      initVideoCmsBlock(parentEl)
    })
  }, [])

  return null
}

export default CmsBlockClient
