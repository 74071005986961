'use client'

import { useRouter } from 'next/navigation'
import { useTranslation } from '../../../context/ConfigContext'
import { buildUrlSearchProductsPage } from './HeaderSearch.utils'
import Icon from '../../Icon/Icon'
import { Icons } from '../../Icon/constants'
import { SubmitHandler, useForm } from 'react-hook-form'

type HeaderSearchInputProps = {
  onClose: () => void
  valueSearch: string
  onChange: (value: string) => void
}

const HeaderSearchInput = ({ onClose, valueSearch, onChange }: HeaderSearchInputProps) => {
  const router = useRouter()
  const t = useTranslation()
  const { handleSubmit, register } = useForm<{ valueSearch: string }>({
    defaultValues: {
      valueSearch,
    },
  })

  const onSubmit: SubmitHandler<{ valueSearch: string }> = (data) => {
    const url = buildUrlSearchProductsPage(data.valueSearch)
    router.push(url)
    onClose()

    const headerEl = document.querySelector('header')
    headerEl?.classList.remove('header-mobile-opened')
    const search = document.querySelector('.header-search')
    search?.classList.add('hidden')
  }

  return (
    <form className='header-search-input' onSubmit={handleSubmit(onSubmit)}>
      <input
        type='text'
        placeholder={t('What are you looking for?', {}, false)}
        {...register('valueSearch', {
          onChange: (e) => onChange(e.target.value),
        })}
        autoFocus={true}
      />
      <button type='button' className='header-search-input-close' onClick={onClose}>
        <Icon iconName={Icons.CLOSE} />
      </button>
    </form>
  )
}

export const HeaderSearchInputMobile = ({
  valueSearch,
  onChange,
  onClose,
}: HeaderSearchInputProps) => {
  const router = useRouter()
  const t = useTranslation()

  const onSubmit = () => {
    const url = buildUrlSearchProductsPage(valueSearch)
    router.push(url)
    onClose()

    const headerEl = document.querySelector('header')
    headerEl?.classList.remove('header-mobile-opened')
  }

  return (
    <form
      className='header-search-input-mobile'
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit()
        const headerEl = document.querySelector('header')
        headerEl?.classList.remove('header-mobile-opened')
      }}>
      <Icon iconName={Icons.SEARCH} className='header-search-input-mobile-serchicon' />
      <input
        type='text'
        placeholder={t('What are you looking for?', {}, false)}
        value={valueSearch}
        onChange={(e) => onChange(e.target.value)}
        autoFocus={false}
      />
      {valueSearch && (
        <button type='button' className='header-search-mobile-close' onClick={() => onChange('')}>
          {t('Annuler', {}, true)}
        </button>
      )}
    </form>
  )
}

export default HeaderSearchInput
