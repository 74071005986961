'use client'

import { CategoryHitAlgolia } from '../../../../../queries/algolia/ResultFetchAlgoliaByQuery.types'
import { useTranslation } from '../../../context/ConfigContext'
import Link from '../../Link/Link'

type HeaderSearchResultsCategoriesProps = {
  categories: CategoryHitAlgolia[]
}

const HeaderSearchResultsCategories = ({ categories }: HeaderSearchResultsCategoriesProps) => {
  const t = useTranslation()

  if (!categories.length) {
    return (
      <div className='header-search-results-categories noresult'>
        <p className='header-search-results-title'>{t('footer-links-colonne3-title', {}, true)}</p>
        <p className='noresult-title'>{t('No results', {}, true)}</p>
      </div>
    )
  }

  return (
    <div className='header-search-results-categories'>
      <p className='header-search-results-title'>{t('footer-links-colonne3-title', {}, true)}</p>
      <ul className='header-search-results-categories-list'>
        {categories.map((category) => (
          <li key={category.objectID} className='header-search-results-categories-item'>
            <Link href={category.url}>{category.name}</Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default HeaderSearchResultsCategories
