import { buildFullUrlMedia } from '../../../../core/hook/utils'
import Image from '../../Image/Image'
import Link from '../../Link/Link'

export const pushimageCategoryClassName = 'header-navigation-desktop-pushimage-category'

type HeaderNavigationDesktopPushImageProps = {
  pushHref?: string
  pushSrc?: string
  pushLabel?: string
  dataCategoryId?: string
  extraClassName?: string
}

const HeaderNavigationDesktopPushImage = ({
  pushHref,
  pushSrc,
  pushLabel,
  dataCategoryId,
  extraClassName,
}: HeaderNavigationDesktopPushImageProps) => (
  <Link
    href={pushHref ?? ''}
    className={`header-navigation-desktop-pushimage ${extraClassName} ${
      dataCategoryId ? pushimageCategoryClassName : ''
    }`}
    dataCategory={dataCategoryId}>
    {pushSrc && (
      <picture className='contrast'>
        <Image src={buildFullUrlMedia(pushSrc)} alt={pushLabel ?? ''} width={275} height={370} />
      </picture>
    )}
    <span className='header-navigation-desktop-pushimage-title'>{pushLabel}</span>
  </Link>
)

export default HeaderNavigationDesktopPushImage
