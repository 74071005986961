'use client'

import { useEffect, useState } from 'react'

export const useInfoPushLocalStorage = () => {
  const [showInfoPush, setShowInfoPush] = useState(false)

  useEffect(() => {
    const value = localStorage.getItem('info-push-show')

    if (value === null) {
      localStorage.setItem('info-push-show', 'true')
      setShowInfoPush(true)
      document.querySelector('main')?.classList.add('has-message-push')
      return
    }

    if (value === 'true') {
      setShowInfoPush(true)
      document.querySelector('main')?.classList.add('has-message-push')
      return
    }

    setShowInfoPush(false)
  }, [])

  const onCloseInfoPush = () => {
    localStorage.setItem('info-push-show', 'false')
    setShowInfoPush(false)
    document.querySelector('main')?.classList.remove('has-message-push')
  }

  return {
    showInfoPush,
    onCloseInfoPush,
  }
}

export default useInfoPushLocalStorage
