import { SVGProps } from 'react'

export const IconArrow = (props: SVGProps<SVGSVGElement>) => (
  <button>
    <svg
      width='8'
      height='14'
      viewBox='0 0 8 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path d='M1 1L7 7L1 13' stroke='#fff' strokeLinecap='square' strokeLinejoin='round' />
    </svg>
  </button>
)

export const ArrowNavigationLeft = (props: SVGProps<SVGSVGElement>) => (
  <IconArrow className='custom-arrow-navigation custom-arrow-navigation-left' {...props} />
)

export const ArrowNavigationRight = (props: SVGProps<SVGSVGElement>) => (
  <IconArrow className='custom-arrow-navigation custom-arrow-navigation-right' {...props} />
)
