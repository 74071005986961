import ResultFetchAlgoliaByQuery, {
  CategoryHitAlgolia,
  PageHitAlgolia,
  ProductHitAlgolia,
} from './ResultFetchAlgoliaByQuery.types'
import { DEFAULT_LOCAL_COUNTRY, fetchAlgoliaByQueries } from './algolia.client'
import { SearchClient } from 'algoliasearch'

type ResultFetchAlgoliaByMultipleQueries = [
  ResultFetchAlgoliaByQuery<CategoryHitAlgolia>,
  ResultFetchAlgoliaByQuery<ProductHitAlgolia>,
  ResultFetchAlgoliaByQuery<PageHitAlgolia>,
]

const fetchMultipleSectionsByValueSearch = async (
  client: SearchClient,
  indexPrefix: string,
  valueSearch: string,
  localCountry = DEFAULT_LOCAL_COUNTRY,
) => {
  const params = {
    query: valueSearch,
    hitsPerPage: 3,
    analyticsTags: 'autocomplete',
    clickAnalytics: 'true',
  }
  const requests = [
    {
      indexName: `${indexPrefix}${localCountry}_categories`,
      params,
    },
    {
      indexName: `${indexPrefix}${localCountry}_products`,
      params: {
        ...params,
        hitsPerPage: 6,
      },
    },
    {
      indexName: `${indexPrefix}${localCountry}_pages`,
      params,
    },
  ]

  const result = await fetchAlgoliaByQueries<ResultFetchAlgoliaByMultipleQueries>(client, requests)

  const [categories, products, pages] = result
  return { categories, products, pages }
}

export default fetchMultipleSectionsByValueSearch
