interface SwitchProps {
  label: string
  color?: string
  backgroundColor?: string
  onChange?: () => void
  value?: boolean
  disabled?: boolean
  checked?: boolean
  className?: string
}

export const Switch = ({
  label,
  color,
  onChange,
  value = false,
  disabled = false,
  checked,
  className,
  ...props
}: SwitchProps) => (
  <label className={['switch', `switch--${color}`].join(' ')} {...props} aria-disabled={disabled}>
    <div className='checkbox'>
      {checked !== undefined ? (
        <input
          type='checkbox'
          id='toggle'
          name='toggle'
          onChange={onChange}
          disabled={disabled}
          checked={checked}
          aria-pressed={checked}
        />
      ) : (
        <input
          defaultChecked={value}
          type='checkbox'
          id='toggle'
          name='toggle'
          onChange={onChange}
          disabled={disabled}
          aria-pressed={checked}
        />
      )}
      <span className='decorator' />
    </div>
    <span className={`label ${className}`}>{label}</span>
  </label>
)
