import algoliasearch, { SearchClient } from 'algoliasearch'
import { useContext } from 'react'
import { DefaultStoreCode } from '../../src/domain/store'
import { ConfigContext } from '../../src/presentation/context/ConfigContext'

export const DEFAULT_LOCAL_COUNTRY = DefaultStoreCode

export type QueryRequestParams = Record<string, number | string | (string | string[])[]>

export type QueryRequest = {
  indexName: string
  params: QueryRequestParams
}

const getClientAlgoliaSearch = (appId: string, apiKey: string) => {
  const client = algoliasearch(appId, apiKey)

  return client
}

export const useGetClientAlgoliaSearch = () => {
  const { storeConfig } = useContext(ConfigContext)

  const {
    algoliasearch_credentials_credentials_application_id: appId,
    algoliasearch_credentials_credentials_search_only_api_key: apiKey,
  } = storeConfig

  if (!appId || !apiKey) {
    throw new Error('missing credentials for algolia')
  }

  return getClientAlgoliaSearch(appId, apiKey)
}

export async function fetchAlgoliaByQueries<T>(client: SearchClient, requests: QueryRequest[]) {
  const { results } = await client.multipleQueries(requests)
  return results as T
}

export async function fetchAlgoliaBySingleQuery<T>(
  client: SearchClient,
  request: QueryRequest,
  queryName: string,
) {
  const index = client.initIndex(request.indexName)
  const results = await index.search(queryName, request.params)
  return results as T
}
