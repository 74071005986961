import { useContext, useEffect } from 'react'
import { AlertNotificationContext } from '../../context/AlertNotificationContext'
import Icon from '../Icon/Icon'
import { Icons } from '../Icon/constants'

const HeaderAlertNotification = () => {
  const { message, onCloseNotification } = useContext(AlertNotificationContext)

  useEffect(() => {
    document.querySelector('.header-alert-notification')
  }, [message])

  if (!message) {
    return null
  }

  return (
    <div className='header-alert-notification'>
      <p className='header-alert-notification-message'>{message}</p>
      <Icon
        iconName={Icons.CLOSE}
        className='header-alert-notification-close'
        onClick={onCloseNotification}
      />
    </div>
  )
}

export default HeaderAlertNotification
