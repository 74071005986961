import { ReactNode, useState } from 'react'
import HeaderNavigationMobileLink from './HeaderNavigationMobileLink'

type HeaderNavigationMobileLevelProps = {
  levelTitle: string
  levelHref: string
  children: ReactNode
  onClose: () => void
}

const HeaderNavigationMobileLevel = ({
  levelTitle,
  levelHref,
  children,
  onClose
}: HeaderNavigationMobileLevelProps) => {
  const [isActive, setIsActive] = useState(false)

  const handleToggle = () => {
    setIsActive(!isActive)
  }

  return (
    <li className='header-navigation-mobile-level'>
      <HeaderNavigationMobileLink title={levelTitle} href={levelHref} onClick={handleToggle}
        onClose={onClose}
      />
      <div className={`header-navigation-mobile-level-submenu ${isActive ? 'active' : ''}`}>
        <HeaderNavigationMobileLink
          title={levelTitle}
          href={levelHref}
          onClick={handleToggle}
          onClose={onClose}
          isBacktrack
          bold
        />
        {children}
      </div>
    </li>
  )
}

export default HeaderNavigationMobileLevel
