'use client'

import { PageHitAlgolia } from '../../../../../queries/algolia/ResultFetchAlgoliaByQuery.types'
import { useTranslation } from '../../../context/ConfigContext'
import Link from '../../Link/Link'

const HeaderSearchResultsPageItem = ({ page }: { page: PageHitAlgolia }) => {
  // eslint-disable-next-line no-underscore-dangle
  const description = page._snippetResult?.content.value

  return (
    <li className='header-search-results-pages-item'>
      <Link href={page.url}>
        <p className='header-search-results-pages-item-title'>{page.name}</p>
        {description && (
          <p
            className='header-search-results-pages-item-desc'
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </Link>
    </li>
  )
}

type HeaderSearchResultsPagesProps = {
  pages: PageHitAlgolia[]
}

const HeaderSearchResultsPages = ({ pages }: HeaderSearchResultsPagesProps) => {
  const t = useTranslation()
  if (!pages.length) {
    return (
      <div className='header-search-results-pages noresult'>
        <p className='header-search-results-title'>{t('Pages', {}, true)}</p>
        <p className='noresult-title'>{t('No results', {}, true)}</p>
      </div>
    )
  }

  return (
    <div className='header-search-results-pages'>
      <p className='header-search-results-title'>{t('Pages', {}, true)}</p>
      <ul className='header-search-results-pages-list'>
        {pages.map((page) => (
          <HeaderSearchResultsPageItem key={page.objectID} page={page} />
        ))}
      </ul>
    </div>
  )
}

export default HeaderSearchResultsPages
