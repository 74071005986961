const playVideo = function (el: HTMLVideoElement) {
  el.play()
}

const stopVideo = function (el: HTMLVideoElement, toStart = false) {
  el.pause()
  if (toStart) el.currentTime = 0
}

const openFullscreen = function (el: HTMLVideoElement) {
  if (el.requestFullscreen) {
    el.requestFullscreen()
  }
}

const closeFullscreen = function () {
  if (document.exitFullscreen) {
    document.exitFullscreen()
  }
}

const setCustomControls = function (element: HTMLElement, videoEl: HTMLVideoElement) {
  const videoWrapper = element

  if (!videoWrapper || videoWrapper.getElementsByClassName('js-video-remote').length > 0) {
    return
  }

  videoEl.removeAttribute('controls')

  const playToggler = document.createElement('div')
  playToggler.classList.add('js-play-toggler')
  playToggler.classList.add('icon-play')

  const soundToggler = document.createElement('div')
  soundToggler.classList.add('js-sound-toggler')
  soundToggler.classList.add('icon-sound')

  const fullscreenToggler = document.createElement('div')
  fullscreenToggler.classList.add('js-fullscreen-toggler')
  fullscreenToggler.classList.add('icon-fs')

  const customRemote = document.createElement('div')
  customRemote.classList.add('js-video-remote')

  customRemote.appendChild(playToggler)
  customRemote.appendChild(soundToggler)
  customRemote.appendChild(fullscreenToggler)

  videoWrapper.appendChild(customRemote)

  const playToggleFn = function () {
    if (videoEl.paused) {
      playVideo(videoEl)
    } else {
      stopVideo(videoEl)
    }
  }

  const soundToggleFn = function () {
    if (videoEl.muted) {
      videoEl.muted = false
    } else {
      videoEl.muted = true
    }
  }

  const fullscreenToggleFn = function () {
    const isFullscreen = window.innerHeight == screen.height
    if (isFullscreen) {
      closeFullscreen()
    } else {
      openFullscreen(videoEl)
    }
  }

  soundToggler.addEventListener(
    'click',
    function () {
      soundToggleFn()
    },
    false,
  )
  fullscreenToggler.addEventListener(
    'click',
    function () {
      fullscreenToggleFn()
    },
    false,
  )
  playToggler.addEventListener(
    'click',
    function () {
      playToggleFn()
    },
    false,
  )
  videoEl.addEventListener(
    'play',
    function () {
      playToggler.classList.add('active')
    },
    false,
  )
  videoEl.addEventListener(
    'pause',
    function () {
      playToggler.classList.remove('active')
    },
    false,
  )
  videoEl.addEventListener(
    'volumechange',
    function () {
      if (videoEl.muted) {
        soundToggler.classList.remove('active')
      } else {
        soundToggler.classList.add('active')
      }
    },
    false,
  )
}

export const initVideoCmsBlock = (videoWrapperEl: HTMLElement) => {
  const videoEl = videoWrapperEl.querySelector<HTMLVideoElement>('.js-autoplay-video')

  if (!videoEl || !videoWrapperEl) {
    return
  }

  setCustomControls(videoWrapperEl, videoEl)
}
