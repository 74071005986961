'use client'

import { useState } from 'react'
import ClickAwayListener from 'react-click-away-listener'
import HeaderSearchInput from './HeaderSearchInput'
import HeaderSearchResults from './HeaderSearchResults'
import clsx from 'clsx'

type HeaderSearchProps = {
  onClose: () => void
  className?: string,
}

const HeaderSearch = ({ onClose, className }: HeaderSearchProps) => {
  const [valueSearch, setValueSearch] = useState('')

  return (
    <ClickAwayListener onClickAway={onClose}>
      <div className={clsx('header-search', className)}>
        <HeaderSearchInput onClose={onClose} valueSearch={valueSearch} onChange={setValueSearch} />
        <HeaderSearchResults valueSearch={valueSearch} />
      </div>
    </ClickAwayListener>
  )
}

export default HeaderSearch
