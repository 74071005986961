'use client'

import { FunctionComponent } from 'react'
import useCurrentLocale from '../../../core/hook/useCurrentLocale'
import { useTranslation } from '../../context/ConfigContext'
import { stores, Store } from '../../../data/stores'
import useCurrentStore from '../../../core/hook/useCurrentStore'
import { findStoreByLocaleOrPrefix } from '../../../domain/store'

type ConfiguratorType = {
  displayShipping?: boolean
  className?: string
}

export const Configurator: FunctionComponent<ConfiguratorType> = ({
  className,
  displayShipping,
}) => {
  const currentLocale = useCurrentLocale()
  const t = useTranslation()
  const currentStore = useCurrentStore()
  const currentRegionCode = currentStore?.region_code ?? 'fr'

  const regions: Record<string, Store[]> = {}
  for (const store of stores) {
    if (!regions[store.region_code]) {
      regions[store.region_code] = []
    }
    regions[store.region_code].push(store)
  }

  // Region change function
  const handleRegionChange = (newRegion: string) => {
    const storesInRegion = regions[newRegion]

    for (const store of storesInRegion) {
      const alternateLink = document.querySelector(
        `link[rel="alternate"][hreflang="${store.locale}"]`,
      ) as HTMLLinkElement | null

      if (alternateLink) {
        window.location.href = alternateLink.href
        return
      }
    }
    // Fallback to homepage of the selected region
    window.location.href = `/${storesInRegion[0].prefix}`
  }

  // Language change function
  const handleLanguageChange = (newLocale: string) => {
    const alternateLink = document.querySelector(
      `link[rel="alternate"][hreflang="${newLocale}"]`,
    ) as HTMLLinkElement | null
    console.log(alternateLink, newLocale)

    if (alternateLink) {
      window.location.href = alternateLink.href
      return
    }
    const store = stores.find((s) => s.locale === newLocale)
    if (store) {
      window.location.href = `/${store.prefix}`
    }
  }

  return (
    <ul className={`footer-selects ${className}`}>
      {displayShipping && (
        <li className='footer-select'>
          <label htmlFor='dispatching'>{t('footer-select-website-label')}</label>
          <select
            className={`width`}
            id='dispatching'
            name='dispatching'
            defaultValue={currentRegionCode}
            onChange={(e) => {
              const selectedRegion = e.target.value
              handleRegionChange(selectedRegion)
            }}>
            {Object.keys(regions).map((code) => (
              <option value={regions[code][0].region_code} key={regions[code][0].region_code}>
                {regions[code][0].labels.region}
              </option>
            ))}
          </select>
        </li>
      )}
      <li className='footer-select'>
        <label htmlFor='store'>{t('footer-select-storeview-label')}</label>
        <select
          className={`width ${currentLocale}`}
          id='store'
          name='store'
          defaultValue={stores.find((store) => store.prefix === currentLocale)?.locale ?? undefined}
          onChange={(e) => {
            const newStore = findStoreByLocaleOrPrefix(e.target.value)
            handleLanguageChange(newStore.locale)
          }}>
          {regions[currentRegionCode].map((store) => {
            return (
              <option value={store.locale ?? ''} key={store.locale}>
                {store.labels.language}
              </option>
            )
          })}
        </select>
      </li>
    </ul>
  )
}
