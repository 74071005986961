'use client'

import { IconArrow } from '../../SliderUtils/ArrowNavigation'
import HeaderNavigationMobileLevel from './HeaderNavigationMobileLevel'
import HeaderNavigationMobileLink from './HeaderNavigationMobileLink'
import { useContext, useState } from 'react'
import { HeaderSearchInputMobile } from '../HeaderSearch/HeaderSearchInput'
import HeaderSearchResults from '../HeaderSearch/HeaderSearchResults'
import {
  MenuBlockItem,
  MenuBlockItemBlockCategory,
  MenuBlockItemBlockImage,
  MenuBlockItemCategory,
} from '../../../../domain/menuBlock.types'
import { UserContext } from '../../../context/UserContext'
import { buildFullUrlMedia } from '../../../../core/hook/utils'
import { useTranslation } from '../../../context/ConfigContext'
import Icon from '../../Icon/Icon'
import { Icons } from '../../Icon/constants'
import Image from '../../Image/Image'
import Link from '../../Link/Link'

const HeaderNavigationMobileCategoryImage = ({ category, onClose }: { category: MenuBlockItemCategory, onClose: () => void }) => (
  <>
    {category.mobile_thumbnail_image ? (
      <li className='header-navigation-mobile-category-withimage'>
        <Link href={category.url_path || ''} onClick={() => {onClose()}}>
          <picture>
            <Image
              src={buildFullUrlMedia(category.mobile_thumbnail_image)}
              alt={category.name ?? ''}
              width={200}
              height={200}
            />
          </picture>
          <p>{category.name}</p>
        </Link>
      </li>
    ) : (
      <li className='header-navigation-mobile-category'>
        <HeaderNavigationMobileLink
          onClose={onClose}
          title={category.name ?? ''}
          href={category.url_path ?? ''}
          noArrow
        />
      </li>
    )}
  </>
)

const HeaderNavigationMobileCmsPage = ({ cms_page, onClose }: { cms_page, onClose: () => void }) => (
  <li className='header-navigation-mobile-category'>
    <HeaderNavigationMobileLink
      onClose={onClose}
      title={cms_page?.menu_title ?? cms_page.title}
      href={`${cms_page?.url_key}`}
      noArrow
    />
  </li>
)

const HeaderNavigationMobileCategory = ({ name, href, onClose }: { name: string; href: string, onClose: () => void }) => (
  <li className='header-navigation-mobile-category'>
    <HeaderNavigationMobileLink onClose={onClose} title={name} href={href} noArrow />
  </li>
)

const HeaderNavigationMobileBlockCategory = ({
  item,
  hrefItem,
  onClose
}: {
  item: MenuBlockItemBlockCategory
  hrefItem?: string
  onClose: () => void
}) => {
  const { images_hover } = item
  const categories = item.categories?.filter((category) => !!category) as MenuBlockItemCategory[]
  const cmsPages = item.cms_pages?.filter((cms_page) => !!cms_page)

  return (
    <HeaderNavigationMobileLevel onClose={onClose} levelTitle={item.title ?? ''} levelHref='#' key={item.title}>
      {images_hover ? (
        <ul className='header-navigation-mobile-categories-withimages'>
          {item.collections_link_label && hrefItem && (
            <li className='header-navigation-mobile-seemore'>
              <Link href={hrefItem} onClick={() => {onClose()}}>
                <span>{item.collections_link_label}</span>
                <IconArrow />
              </Link>
            </li>
          )}
          {categories?.map((category) => (
            <HeaderNavigationMobileCategoryImage
              onClose={onClose}
              key={`header-navigation-mobile-category-image-${category.name}`}
              category={category}
            />
          ))}
        </ul>
      ) : (
        <ul className='header-navigation-mobile-categories'>
          {item.collections_link_label && hrefItem && (
            <li className='header-navigation-mobile-seemore'>
              <Link href={hrefItem} onClick={() => {onClose()}}>
                <span>{item.collections_link_label}</span>
                <IconArrow />
              </Link>
            </li>
          )}
          {categories?.map((category) => (
            <HeaderNavigationMobileCategory
              onClose={onClose}
              key={`header-navigation-mobile-category-${category.name}`}
              name={category.name ?? ''}
              href={category.url_path ?? ''}
            />
          ))}
          {cmsPages && (
            <>
              {cmsPages?.map((cms_page) => (
                <HeaderNavigationMobileCmsPage
                  onClose={onClose}
                  cms_page={cms_page}
                  key={`cms-page-${cms_page?.title}`}
                />
              ))}
            </>
          )}
        </ul>
      )}
    </HeaderNavigationMobileLevel>
  )
}

const HeaderNavigationMobileItem = ({ item, onClose }: { item: MenuBlockItem, onClose: () => void }) => {
  const hrefItem = item.category?.url_path ?? item.url ?? ''

  if (!item.submenu?.block_categories && !item.submenu?.block_images) {
    return (
      <li className='header-navigation-mobile-item'>
        <HeaderNavigationMobileLink onClose={onClose} title={item.title ?? ''} href={hrefItem} noArrow />
      </li>
    )
  }

  const blockCategories = item.submenu.block_categories?.filter((bc) => !!bc) as
    | MenuBlockItemBlockCategory[]
    | undefined
  const blockImages = item.submenu.block_images?.filter((blockImage) => !!blockImage) as
    | MenuBlockItemBlockImage[]
    | undefined

  return (
    <HeaderNavigationMobileLevel onClose={onClose} levelTitle={item.title ?? ''} levelHref={hrefItem}>
      {blockCategories && (
        <ul className='header-navigation-mobile-blockcategories'>
          {item.see_all_text && (
            <li className='see-all'>
              <Link href={item.url || ''} onClick={() => {onClose()}}>
                <span>{item.see_all_text}</span>
                <IconArrow />
              </Link>
            </li>
          )}

          {blockCategories.map((blockCategory) => (
            <HeaderNavigationMobileBlockCategory
              onClose={onClose}
              key={`header-navigation-mobile-block-category-${blockCategory.title}`}
              item={blockCategory}
              hrefItem={hrefItem}
            />
          ))}
        </ul>
      )}
      {blockImages && (
        <ul className='header-navigation-mobile-blockcategories'>
          {item.see_all_text && (
            <li className='see-all'>
              <Link href={item.url || ''}>
                <span>{item.see_all_text}</span>
                <IconArrow />
              </Link>
            </li>
          )}

          {blockImages?.map((blockImage) => (
            <HeaderNavigationMobileCategory
              onClose={onClose}
              key={`header-navigation-mobile-category-${blockImage.link}`}
              name={blockImage.image_text ?? ''}
              href={blockImage.link ?? ''}
            />
          ))}
        </ul>
      )}
    </HeaderNavigationMobileLevel>
  )
}

type HeaderMenuItemsForMobileProps = {
  onClose: () => void
  menuItems: MenuBlockItem[]
}

const filterItemsForMobile = (menuItems: MenuBlockItem[]) =>
  menuItems.filter(
    (menuItem) => menuItem.item_class !== 'only-mobile' && menuItem.item_class !== 'hidden' && (menuItem.position ?? 0) <= 1000,
  )

const filterItemsForMobileSecondaryMenu = (menuItems: MenuBlockItem[]) =>
  menuItems.filter(
    (menuItem) => (menuItem.position ?? 0) >= 1000,
  )

const HeaderNavigationMobile = ({ onClose, menuItems }: HeaderMenuItemsForMobileProps) => {
  const [valueSearch, setValueSearch] = useState('')
  const filteredItems = filterItemsForMobile(menuItems)

  const filteredItemsForSmallMenu = filterItemsForMobileSecondaryMenu(menuItems)

  const { isLogged } = useContext(UserContext)
  const t = useTranslation()

  const acountLink: string = isLogged ? '/customer/account' : '/customer/login'
  const wishlist: string = isLogged ? '/customer/dashboard/wishlist' : '/customer/login'

  return (
    <header className='header-navigation-mobile'>
      <div className='header-navigation-mobile-wrapper'>
        <button onClick={onClose} type='button' className='header-navigation-mobile-close'>
          <Icon iconName={Icons.CLOSE} />
        </button>

        <HeaderSearchInputMobile
          valueSearch={valueSearch}
          onChange={setValueSearch}
          onClose={onClose}
        />

        <nav className='header-navigation-mobile-menu'>
          <ul className='header-navigation-mobile-menu-items'>
            {filteredItems.map((item) => (
              <HeaderNavigationMobileItem
                onClose={onClose}
                item={item}
                key={`header-navigation-mobile-item-${item.title}`}
              />
            ))}
          </ul>

          <div className='header-navigation-mobile-contact'>
            <Link href={acountLink}>
              <Icon iconName={Icons.USER} />
              <span>{isLogged ? t('My Account', {}, true) : t('Login')}</span>
            </Link>
            <Link href={wishlist}>
              <Icon iconName={Icons.HEART} width={22} height={22} />
              <span>{t('header-mobile-fav')}</span>
            </Link>

            {filteredItemsForSmallMenu.length === 0 && (
              <>
                <Link href='faq'>
                  <Icon iconName={Icons.HELP_CIRCLE} />
                  <span>{t('checkout-help-title')}</span>
                </Link>
                <Link href='services'>
                  <Icon iconName={Icons.BELL} />
                  <span>{t('Services')}</span>
                </Link>
              </>
            )}
            {filteredItemsForSmallMenu.map((item) => (
              <Link
                href={item.url || ''}
                key={`mobile-secondary-menu-item-${item.title}`}
                className="header-navigation-mobile-contact-link"
              >
                {item.item_class === 'only-mobile-bell' ? (
                  <Icon iconName={Icons.BELL} />
                ) : item.item_class === 'only-mobile-help-circle' ? (
                  <Icon iconName={Icons.HELP_CIRCLE} />
                ) : null}
                <span>{item.title}</span>
              </Link>
            ))}
          </div>

          {valueSearch && (
            <div className='header-navigation-mobile-search-results'>
              <HeaderSearchResults valueSearch={valueSearch} />
            </div>
          )}
        </nav>
      </div>
    </header>
  )
}

export default HeaderNavigationMobile
